/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.radioButton input[type='radio'] {
  position: absolute;
  height: 1px;
  width: 1px;
  background: none;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0; }
  .radioButton input[type='radio']:checked + .label::before {
    border: calc(0.25 * var(--GW-RADIO-BUTTON-SIZE)) solid var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR); }
  .radioButton input[type='radio']:not(:checked) + .label:hover::before {
    background-color: var(--GW-SELECTOR-BACKGROUND-COLOR-HOVER); }
  .radioButton input[type='radio']:not(:checked) + .label:active::before {
    background-color: var(--GW-SELECTOR-BACKGROUND-COLOR-ACTIVE); }
  .radioButton input[type='radio'][disabled] + .label {
    opacity: var(--GW-OPACITY-DISABLED);
    cursor: default; }
  .radioButton input[type='radio']:focus + .label::before {
    outline: none;
    box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT); }

.radioButton .primaryText {
  color: var(--GW-RADIO-BUTTON-PRIMARY-COLOR);
  vertical-align: middle;
  margin-left: var(--GW-SPACING-2);
  font-size: var(--GW-FONT-SIZE-LABEL);
  line-height: var(--GW-LINE-HEIGHT-LABEL); }

.radioButton .secondaryText {
  color: var(--GW-RADIO-BUTTON-SECONDARY-COLOR);
  vertical-align: middle;
  margin-left: var(--GW-SPACING-2);
  font-size: var(--GW-FONT-SIZE-LABEL);
  line-height: var(--GW-LINE-HEIGHT-LABEL); }

.label {
  text-align: left;
  display: inline-block;
  cursor: pointer;
  margin: var(--GW-SPACING-2) 0; }
  .label::before {
    content: '';
    display: inline-block;
    border-radius: var(--GW-BORDER-RADIUS-FULL);
    width: var(--GW-RADIO-BUTTON-SIZE);
    height: var(--GW-RADIO-BUTTON-SIZE);
    border: 1px solid var(--GW-BORDER-COLOR);
    background-color: transparent;
    vertical-align: middle; }
  .label:focus {
    outline: none;
    box-shadow: none; }
    .label:focus::before {
      outline: none;
      box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);
      border-color: var(--GW-FIELD-COMPONENT-BORDER-COLOR-FOCUS); }

/*# sourceMappingURL=RadioButton.module.css.map */