/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.panel {
  background-color: var(--GW-PANEL-BACKGROUND-COLOR);
  border-radius: var(--GW-BORDER-RADIUS);
  box-shadow: var(--GW-SHADOW-2);
  padding-top: var(--GW-LAYOUT-6);
  padding-bottom: var(--GW-LAYOUT-12); }
  @media (max-width: 580px) {
    .panel {
      padding: var(--GW-LAYOUT-5) var(--GW-LAYOUT-2) var(--GW-LAYOUT-10); } }

.fluid {
  padding-right: var(--GW-LAYOUT-6);
  padding-left: var(--GW-LAYOUT-6); }
  @media (max-width: 1024px) {
    .fluid {
      padding-right: var(--GW-LAYOUT-4);
      padding-left: var(--GW-LAYOUT-4); } }

.center {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.header {
  margin-bottom: var(--GW-LAYOUT-6);
  width: 100%; }

.title {
  display: block;
  color: var(--GW-PANEL-TITLE-COLOR);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  font-size: var(--GW-FONT-SIZE-H1);
  line-height: var(--GW-LINE-HEIGHT-H1); }
  @media (max-width: 580px) {
    .title {
      font-size: var(--GW-FONT-SIZE-H3);
      line-height: var(--GW-LINE-HEIGHT-H3); } }

.subTitle {
  display: block;
  color: var(--GW-PANEL-SUBTITLE-COLOR); }

/*# sourceMappingURL=Panel.module.css.map */