/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.searchBar {
  display: flex;
  align-items: center;
  padding: var(--GW-LAYOUT-4) var(--GW-LAYOUT-4);
  background-color: var(--GW-TABLE-FILTERBAR-BACKGROUND-COLOR); }
  .searchBar .searchField {
    min-width: var(--GW-FILTER-BAR-INPUT-MIN-WIDTH); }
  .searchBar .manageFiltersButton {
    padding: 0 0.5rem;
    border: 1px solid var(--GW-BORDER-COLOR);
    box-shadow: none;
    margin-left: var(--GW-LAYOUT-4); }
    .searchBar .manageFiltersButton:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT); }
  .searchBar .manageFiltersButtonSelected {
    background-color: var(--GW-FILTER-BAR-MANAGE-BUTTON-BACKGROUND-COLOR); }

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.manageFiltersBadge {
  margin-left: var(--GW-SPACING-4);
  background-color: var(--GW-FILTER-BAR-BADGE-BACKGROUND-COLOR);
  height: 100%; }

.filterBar {
  background-color: var(--GW-FILTER-BAR-BACKGROUND-COLOR);
  box-shadow: var(--GW-SHADOW-INSET);
  padding: var(--GW-LAYOUT-4) var(--GW-LAYOUT-4) 0; }
  .filterBar .titleDivider {
    height: 1px;
    margin: var(--GW-SPACING-4) 0;
    border-top: 1px solid rgba(125, 145, 172, 0.24); }

.filterTitle {
  color: var(--GW-FILTER-BAR-FILTERS-COLOR);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  font-size: var(--GW-FONT-SIZE-H4);
  line-height: var(--GW-LINE-HEIGHT-H4); }

.filterItem {
  min-width: var(--GW-FILTER-BAR-INPUT-MIN-WIDTH);
  margin-bottom: var(--GW-SPACING-3); }

/*# sourceMappingURL=FilterBar.module.css.map */