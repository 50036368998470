.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: var(--GW-LOADER-Z-INDEX);
  height: 100%; }

.text {
  text-align: center;
  padding: var(--GW-SPACING-2) var(--GW-SPACING-3); }

.loaderAlertWrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

/*# sourceMappingURL=Loader.module.css.map */