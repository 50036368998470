.actionTitleBar.actionTitleBarContainer {
  border-bottom: 1px solid var(--GW-BORDER-COLOR);
  padding-bottom: var(--GW-LAYOUT-3);
  margin-bottom: var(--GW-LAYOUT-3); }

.actionTitleBar .title {
  align-self: center; }

.actionTitleBar .titleElement {
  height: 100%; }

/*# sourceMappingURL=ActionTitleBar.module.css.map */