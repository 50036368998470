.popper {
  z-index: var(--GW-Z-INDEX-2); }

.popoverContainer {
  width: var(--GW-NOTIFICATION-ACTION-SIZE); }

.popoverTitle {
  max-width: calc(calc( var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-HEADER-PADDING) * 2 - var(--GW-POPOVER-HEADER-SPACING)) * 0.65); }

.popoverHeaderLink {
  max-width: calc(calc( var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-HEADER-PADDING) * 2 - var(--GW-POPOVER-HEADER-SPACING)) * 0.35); }

.popoverBody {
  max-height: calc( var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-HEADER-HEIGHT) - var(--GW-POPOVER-FOOTER-HEIGHT));
  height: unset; }

.popoverFooterLink {
  max-width: calc( var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-FOOTER-PADDING) * 2 - var(--GW-POPOVER-FOOTER-SPACING)); }

/*# sourceMappingURL=NotificationAction.module.css.map */