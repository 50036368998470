/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.fieldWithIcon {
  position: relative;
  width: 100%; }
  .fieldWithIcon input:not(:first-child) {
    padding-left: calc( 0.75rem + var(--GW-ICON-FONT-SIZE-1) + var(--GW-SPACING-3)); }
  .fieldWithIcon input:not(:last-child) {
    padding-right: calc( var(--GW-SPACING-3) + var(--GW-ICON-FONT-SIZE-1) + 0.75rem); }

.iconWrapper {
  position: absolute;
  text-align: inherit;
  top: 50%;
  transform: translateY(-50%);
  left: 0.75rem; }
  .iconWrapper:last-child {
    left: auto;
    right: 0.75rem; }

.icon {
  color: var(--GW-FIELD-COMPONENT-COLOR);
  vertical-align: middle;
  pointer-events: none;
  font-size: var(--GW-ICON-FONT-SIZE-1);
  line-height: var(--GW-ICON-LINE-HEIGHT); }
  .icon:global(.disabled) {
    opacity: var(--GW-OPACITY-DISABLED); }

/*# sourceMappingURL=FieldIcon.module.css.map */