/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.sideNavigation {
  position: relative;
  height: 100%;
  width: var(--GW-SIDE-NAV-WIDTH);
  transition: width var(--GW-ANIMATION-DURATION); }
  .sideNavigation .content {
    box-shadow: var(--GW-SHADOW-SIDE);
    height: 100%;
    display: flex;
    background-color: var(--GW-SIDE-NAV-CONTENT-BACKGROUND-COLOR); }
  .sideNavigation .accordionCollapse {
    background-color: var(--GW-SIDE-NAV-ACCORDION-BACKGROUND-COLOR); }
  .sideNavigation .layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-top: var(--GW-LAYOUT-6); }
  .sideNavigation .navigationContent {
    transition: width var(--GW-ANIMATION-DURATION);
    flex-wrap: wrap;
    flex: 1;
    padding: var(--GW-LAYOUT-8) 0 var(--GW-LAYOUT-8);
    overflow-x: hidden;
    overflow-y: auto; }
  .sideNavigation .footer {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid var(--GW-SIDE-NAV-BORDER-COLOR);
    padding: var(--GW-LAYOUT-2); }
  .sideNavigation .chevronIcon {
    font-size: var(--GW-SIDE-NAV-CHEVRON-SIZE);
    color: var(--GW-SIDE-NAV-CHEVRON-COLOR);
    pointer-events: all; }
  .sideNavigation .badgeWrapper {
    margin-left: auto; }
  .sideNavigation .notificationWrapper {
    padding-left: 0.75rem; }
  .sideNavigation .badgeDot {
    position: absolute;
    display: none;
    height: var(--GW-SIDE-NAV-DOT-SIZE);
    width: var(--GW-SIDE-NAV-DOT-SIZE);
    border: 1px solid var(--GW-SIDE-NAV-DOT-BORDER-COLOR);
    border-radius: 50%;
    background-color: var(--GW-SIDE-NAV-DOT-BACKGROUND-COLOR);
    right: 0.75rem;
    top: 0.5rem; }

.collapsed {
  width: var(--GW-SIDE-NAV-COLLAPSED-WIDTH);
  overflow: visible; }
  .collapsed .navigationContent {
    width: 100%;
    overflow: hidden; }
    .collapsed .navigationContent .navBarLink .chevron,
    .collapsed .navigationContent .navBarLink .titleWrapper {
      display: none; }
    .collapsed .navigationContent .navBarLink .notificationWrapper {
      margin: var(--GW-SIDE-NAV-ITEM-BADGE-MARGIN-COLLAPSED);
      transform: scale(0.8);
      padding-left: 0; }
    .collapsed .navigationContent .navBarLink .notifications {
      max-width: 1.5rem; }
    .collapsed .navigationContent .accordionCollapse {
      display: none; }

.sideNavAccordion .cardHeading:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT); }

.sideNavAccordion .cardTitle {
  height: auto;
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0; }

.sideNavAccordion .cardBody {
  margin: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: transparent; }

.sideNavAccordion .openedAccordion {
  transform: rotate(180deg); }

.sideNavAccordion .chevron {
  font-size: var(--GW-SIDE-NAV-CHEVRON-SIZE);
  height: var(--GW-SIDE-NAV-CHEVRON-SIZE);
  transition: var(--GW-ANIMATION-DURATION);
  margin-left: 0.75rem; }

.sideNavAccordion .rightAlignChevron {
  margin-left: auto; }

.sideNavAccordion .subItem {
  color: var(--GW-SIDE-NAV-COLOR);
  padding: var(--GW-SIDE-NAV-SUB-ITEM-PADDING); }
  .sideNavAccordion .subItem .notifications {
    font-size: var(--GW-FONT-SIZE-SUBTEXT);
    line-height: var(--GW-LINE-HEIGHT-SUBTEXT);
    height: auto;
    background: none;
    color: var(--GW-SIDE-NAV-COLOR);
    font-weight: var(--GW-FONT-WEIGHT-REGULAR); }
  .sideNavAccordion .subItem:not(:global(.isActiveSideNavItem)):hover {
    color: var(--GW-SIDE-NAV-COLOR-HOVER);
    background-color: var(--GW-SIDE-NAV-SUB-ITEM-BACKGROUND-COLOR-HOVER); }
  .sideNavAccordion .subItem:not(:global(.isActiveSideNavItem)):hover .notifications {
    color: var(--GW-SIDE-NAV-COLOR); }
  .sideNavAccordion .subItem:global(.active) {
    background-color: var(--GW-SIDE-NAV-SUB-ITEM-BACKGROUND-COLOR-HOVER);
    box-shadow: none; }

.sideNavAccordion .expandedAccordion .chevron,
.sideNavAccordion .activeAccordion .chevron {
  color: var(--GW-SIDE-NAV-CHEVRON-COLOR-ACTIVE); }

.chevronWrapper {
  width: var(--GW-SIDE-NAV-CHEVRON-WRAPPER-WIDTH);
  height: var(--GW-SIDE-NAV-CHEVRON-WRAPPER-HEIGHT); }
  .chevronWrapper:hover {
    background-color: var(--GW-SIDE-NAV-ITEM-BACKGROUND-COLOR-HOVER); }
  .chevronWrapper .chevronIcon {
    font-size: var(--GW-SIDE-NAV-CHEVRON-SIZE); }
  .chevronWrapper:hover .chevronIcon {
    color: var(--GW-SIDE-NAV-CHEVRON-COLOR); }

/*# sourceMappingURL=SideNavigation.module.css.map */