.cubeLoaderElement-1 {
  animation-delay: calc(1s / 6.5); }

.cubeLoaderElement-2 {
  animation-delay: calc(1s / 4.3); }

.cubeLoaderElement-3 {
  animation-delay: calc(1s / 3.25); }

.cubeLoaderElement-4 {
  animation-delay: calc(1s / 13); }

.cubeLoaderElement-5 {
  animation-delay: calc(1s / 6.5); }

.cubeLoaderElement-6 {
  animation-delay: calc(1s / 4.3); }

.cubeLoaderElement-7 {
  animation-delay: 0s; }

.cubeLoaderElement-8 {
  animation-delay: calc(1s / 13); }

.cubeLoaderElement-9 {
  animation-delay: calc(1s / 6.5); }

/*# sourceMappingURL=CubeLoaderElement.module.css.map */