.collapsed {
  display: none; }
  .collapsed.show {
    display: block; }
    .collapsed.show tr {
      display: table-row; }
    .collapsed.show tbody {
      display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height var(--GW-ANIMATION-DURATION) ease; }

/*# sourceMappingURL=Collapse.module.css.map */