/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.stepProgressBar {
  display: flex;
  width: 100%;
  counter-reset: step;
  margin-bottom: var(--GW-SPACING-4); }
  .stepProgressBar > div:not(.visited) {
    counter-increment: step; }

.vertical.stepProgressBar {
  flex-direction: column; }

/*# sourceMappingURL=StepProgressBar.module.css.map */