/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.chevron {
  display: block;
  transition: transform var(--GW-ANIMATION-DURATION);
  transform-origin: center;
  outline: none;
  font-size: var(--GW-ICON-FONT-SIZE-3);
  line-height: var(--GW-ICON-LINE-HEIGHT); }

.chevronRight {
  float: right;
  right: var(--GW-SPACING-2); }

.chevronLeft {
  float: left;
  left: var(--GW-SPACING-2); }

.open {
  transform: rotate(90deg); }

/*# sourceMappingURL=Chevron.module.css.map */