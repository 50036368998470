.checkboxGroup {
  padding-top: var(--GW-SPACING-4); }
  .checkboxGroup:first-child {
    padding-top: 0; }

.tooltip {
  position: absolute;
  top: 0;
  bottom: auto; }

/*# sourceMappingURL=CheckboxGroupField.module.css.map */