/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.cubeLoader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.cubeLoaderElement {
  width: var(--GW-CUBE-LOADER-SIZE-ELEMENT);
  height: var(--GW-CUBE-LOADER-SIZE-ELEMENT);
  background-color: var(--GW-CUBE-LOADER-COLOR);
  animation: gw-cubeGridScaleDelay 2s infinite ease-in-out; }

@-webkit-keyframes gw-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3d(1, 1, 1); }
  35% {
    transform: scale3d(0.01, 0.01, 1); } }

@keyframes gw-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3d(1, 1, 1); }
  35% {
    transform: scale3d(0.01, 0.01, 1); } }

.screenReaderOnly {
  white-space: nowrap;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.cubeLoaderElement-1 {
  animation-delay: calc(1s / 6.5); }

.cubeLoaderElement-2 {
  animation-delay: calc(1s / 4.3); }

.cubeLoaderElement-3 {
  animation-delay: calc(1s / 3.25); }

.cubeLoaderElement-4 {
  animation-delay: calc(1s / 13); }

.cubeLoaderElement-5 {
  animation-delay: calc(1s / 6.5); }

.cubeLoaderElement-6 {
  animation-delay: calc(1s / 4.3); }

.cubeLoaderElement-7 {
  animation-delay: 0s; }

.cubeLoaderElement-8 {
  animation-delay: calc(1s / 13); }

.cubeLoaderElement-9 {
  animation-delay: calc(1s / 6.5); }

/*# sourceMappingURL=CubeLoader.module.css.map */