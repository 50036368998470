/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.tooltipIcon {
  color: var(--GW-TOOLTIP-ICON-COLOR);
  padding: 0;
  display: inline-flex;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--GW-ICON-FONT-SIZE-3);
  line-height: var(--GW-ICON-LINE-HEIGHT); }
  .tooltipIcon > i {
    position: relative;
    width: auto; }
  .tooltipIcon:focus > i::after {
    outline: none;
    box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);
    position: absolute;
    left: 7.5%;
    top: 7.5%;
    content: '';
    border-radius: var(--GW-BORDER-RADIUS-FULL);
    height: 85%;
    width: 85%; }

.top {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.left {
  position: absolute;
  right: calc(-1 * var(--GW-SPACING-8));
  top: 50%;
  transform: translateY(-50%); }

/*# sourceMappingURL=TooltipIcon.module.css.map */